<template>
  <div>
    <div>
      <div class="mb-6 w-full flex flex-col items-center justify-center">
        <div>
          <a
            id="profileImage"
            v-if="userDetail.profileImage"
            :href="userDetail.profileImage"
            target="_blank"
          >
            <img :src="userDetail.profileImage" class="block w-24 h-24 object-contain rounded-full" alt />
          </a>
        
          <vs-avatar
            class="block cursor-pointer w-24 h-24 object-contain rounded-full"
            @click="open_in_new_tab(defaultPP)"
            :src="defaultPP"
            v-else
          />
        </div>
        <label class="text-primary font-medium block text-center text-base">
          Change Photo
          <input
            type="file"
            accept="image/*"
            @change="
              uploadImage(
                $event,
                'profileImageUpload',
                'profile-image-upload'
              )
            "
            id="file-input"
            hidden
          />
        </label>
      </div>  
    
    <div class="grid grid-cols lg:grid-cols-2 gap-4">
      <!-- APHRA -->
      <div class="w-full">
        <div
          v-if="
            (this.userDetail.userType === 'doctor' ||
            this.userDetail.userType === 'nurse') && 
            this.$isAuRegion()
          "
        >
          <label>AHPRA Certificate</label>
          <div>
            <p
              v-if="!userDetail.ahpraCertificate"
              id="ahpraCertificate"
            > N/A
            </p>
            <a
              id="ahpraCertificate"
              v-else-if="determineFileType(userDetail.ahpraCertificate) !== 'pdf'"
              :href="userDetail.ahpraCertificate"
              target="_blank"
              class=" flex justify-center"
            >
              <img :src="userDetail.ahpraCertificate" class="img-fluid" alt />
            </a>
            <a
              id="ahpraCertificate"
              v-else
              :href="userDetail.ahpraCertificate"
              target="_blank"
            >
              Click to View
            </a>
          </div>

          <div>
            <label class="block text-center text-base text-primary border border-solid border-primary py-2">
              Change Certificate
              <input
                type="file"
                @change="uploadImage($event, 'aphraUpload', 'aphra-image-upload')"
                id="file-input"
                hidden
              />
            </label>
          </div>
        </div>

      </div>
        <!-- insurance -->
        <div class="w-full">
        <div
          v-if="
            (this.userDetail.userType === 'doctor' ||
            this.userDetail.userType === 'nurse') && 
            this.$isAuRegion()
          "
        >
          <label>Insurance Certificate</label>
          <div>
            <p
              v-if="!userDetail.insuranceCertificate"
              id="insuranceCertificate"
            >
              N/A
            </p>
          
            <a
              id="insuranceCertificate"
              v-else-if="
                determineFileType(userDetail.insuranceCertificate) !== 'pdf'
              "
              :href="userDetail.insuranceCertificate"
              target="_blank"
              class=" flex justify-center"
            >
              <img :src="userDetail.insuranceCertificate" class="img-fluid" alt />
          </a>
          
            <a
              id="insuranceCertificate"
              v-else
              :href="userDetail.insuranceCertificate"
              target="_blank"
            >
              Click to View
            </a>
          </div>

          <div>
            <div>
              <label class="block text-center text-base text-primary border border-solid border-primary py-2">
                Change Certificate
                <input
                  type="file"
                  @change="
                    uploadImage($event, 'insuranceUpload', 'insurance-image-upload')
                  "
                  id="file-input"
                  hidden
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    
      <div class="w-full">
        <vs-input
          :danger="errors.first('firstName') ? true : false"
          :success="!errors.first('firstName') && userDetail.firstName !== ''"
          val-icon-success="done"
          val-icon-danger="error"
          v-validate="'required'"
          data-vv-validate-on="blur"
          data-vv-as="first name"
          name="firstName"
          label-placeholder="First Name"
          placeholder="First Name"
          v-model="userDetail.firstName"
          class="w-full mt-6"
        />
        <span class="text-danger text-sm">{{
          errors.first("firstName")
        }}</span>
      </div>

      <div :class="`w-full`">
        <vs-input
          :danger="errors.first('lastName') ? true : false"
          :success="!errors.first('lastName') && userDetail.lastName !== ''"
          val-icon-success="done"
          val-icon-danger="error"
          v-validate="'required'"
          data-vv-validate-on="blur"
          data-vv-as="last name"
          name="lastName"
          label-placeholder="Last Name"
          placeholder="Last Name"
          v-model="userDetail.lastName"
          class="w-full mt-6"
        />
        <span class="text-danger text-sm">{{
          errors.first("lastName")
        }}</span>
      </div>

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'doctor'"
      > -->
      <!-- <div class="w-full">
          <label style="font-size: 10px; opacity: 0.5">Date Of Birth</label>
          <datepicker
            style="margin-top: 0 !important;"
            :danger="errors.first('dateOfBirth') ? true : false"
            :success="
              !errors.first('dateOfBirth') &&
                userDetail.dateOfBirth !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-model="userDetail.dateOfBirth"
            name="dateOfBirth"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="date of birth"
            label-placeholder="Date Of Birth"
            placeholder="Date Of Birth"
            class="w-full mt-6"
            :input-class="{
                'is-true':
                  !errors.has('dateOfBirth') && userDetail.dateOfBirth,
                'is-danger': errors.has('dateOfBirth')
              }"
            :format="customFormatter"
          ></datepicker>
          <span class="text-danger text-sm">{{ errors.first("dateOfBirth") }}</span>
        </div>-->
      <!-- <div class="w-full">
          <label>Date Of Birth</label>
          <datepicker
            format="dd/MM/yyyy"
            v-model="userDetail.dateOfBirth"
            :input-class="{
              'is-true': !errors.has('dateOfBirth') && userDetail.dateOfBirth,
              'is-danger': errors.has('dateOfBirth'),
            }"
          >
            <template slot="afterDateInput">
              <span
                v-if="userDetail.dateOfBirth"
                class="
                  bg-color-done
                  input-icon-validate
                  vs-input--icon-validate
                "
              >
                <i
                  valiconpack="material-icons"
                  class="vs-icon notranslate icon-scale material-icons null"
                  >done</i
                >
              </span>
              <span
                v-if="errors.has('dateOfBirth')"
                class="
                  bg-color-error
                  input-icon-validate
                  vs-input--icon-validate
                "
              >
                <i
                  valiconpack="material-icons"
                  class="vs-icon notranslate icon-scale material-icons null"
                  >error</i
                >
              </span>
            </template>
          </datepicker>
          <span class="text-danger" style="font-size: 0.75em">
            {{ errors.first("dateOfBirth") }}
          </span>
          <input
            type="hidden"
            data-vv-as="Date of birth"
            data-vv-validate-on="change"
            name="dateOfBirth"
            v-validate="'required'"
            v-model="userDetail.dateOfBirth"
          />
        </div> -->
      <!-- </vs-col> -->
      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'nurse' ||
          this.userDetail.userType === 'doctor'
        "
      >
        <div class="w-full">
          <vs-input
            val-icon-success="done"
            val-icon-danger="error"
            data-vv-validate-on="blur"
            data-vv-as="preferred name"
            name="preferredName"
            label-placeholder="Preferred Name (Optional)"
            placeholder="Preferred Name (optional)"
            v-model="userDetail.preferredName"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("preferredName")
          }}</span>
        </div>
      </vs-col> -->

      <div
        v-if="
          this.userDetail.userType !== 'doctor' &&
          this.userDetail.userType !== 'admin' &&
          this.userDetail.userType !== 'superAdmin' &&
          this.userDetail.userType !== 'clinic' &&
          this.userDetail.userType !== 'subClinic' &&
          this.userDetail.userType !== 'basic'
        "
      >
        <div class="w-full">
          <vs-input
            :danger="errors.first('bestContactEmail') ? true : false"
            :success="
              !errors.first('bestContactEmail') &&
              userDetail.bestContactEmail !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required|email'"
            data-vv-validate-on="blur"
            data-vv-as="best contact email"
            name="bestContactEmail"
            label-placeholder="Best Contact Email"
            placeholder="Best Contact Email"
            v-model="userDetail.bestContactEmail"
            class="w-full mt-6"
          />

          <span class="text-danger text-sm">{{
            errors.first("bestContactEmail")
          }}</span>
        </div>
      </div>

      <div
        v-show="this.userDetail.userType === 'doctor' || this.userDetail.userType === 'basic'"
      >
        <div class="w-full">
          <vs-input
          :danger="errors.first('contactNumber') ? true : false"
          :success="!errors.first('contactNumber') && userDetail.contactNumber !== ''"
          val-icon-success="done"
          val-icon-danger="error"
          v-validate="'required'"
          data-vv-validate-on="blur"
          data-vv-as="contact number"
          name="contactNumber"
          label-placeholder="Contact Numbers"
          placeholder="Contact Numbers"
          v-model="userDetail.contactNumber"
          class="w-full mt-6"
        />
        </div>
        <span class="text-danger text-sm">{{
            errors.first("contactNumber")
          }}</span>
      </div>

      <div
        v-if="
          (this.userDetail.userType === 'doctor' ||
          this.userDetail.userType === 'nurse') && 
          this.$isAuRegion()
        "
      >
        <div class="w-full">
          <vs-input
            :danger="errors.first('ahpraRegistrationNumber') ? true : false"
            :success="
              !errors.first('ahpraRegistrationNumber') &&
              userDetail.ahpraRegistrationNumber !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="{ required: true }"
            data-vv-validate-on="blur"
            data-vv-as="ahpra registration number"
            name="ahpraRegistrationNumber"
            label-placeholder="AHPRA Registration Number"
            placeholder="AHPRA Registration Number"
            v-model="userDetail.ahpraRegistrationNumber"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("ahpraRegistrationNumber")
          }}</span>
        </div>
      </div>

      <div
        v-if="
          (this.userDetail.userType === 'doctor' ||
          this.userDetail.userType === 'nurse') && 
          this.$isAuRegion()
        "
      >
        <div class="w-full">
          <label style="font-size: 10px; opacity: 0.5">AHPRA Exiry Date</label>
          <datepicker
            style="margin-top: 0 !important"
            :danger="errors.first('ahpraExiryDate') ? true : false"
            :success="
              !errors.first('ahpraExiryDate') &&
              userDetail.ahpraExiryDate !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-model="userDetail.ahpraExiryDate"
            name="ahpraExiryDate"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="ahpra exiry date"
            label-placeholder="Ahpra Exiry Date"
            placeholder="Ahpra Exiry Date"
            class="w-full mt-6"
            :format="customFormatter"
          ></datepicker>
          <span class="text-danger text-sm">{{
            errors.first("ahpraExiryDate")
          }}</span>
        </div>
      </div>

      <div
        v-if="
          (this.userDetail.userType === 'doctor' ||
          this.userDetail.userType === 'nurse') && 
          this.$isAuRegion()
        "
      >
        <div class="w-full">
          <vs-input
            :danger="errors.first('indemnityInsuranceProvider') ? true : false"
            :success="
              !errors.first('indemnityInsuranceProvider') &&
              userDetail.indemnityInsuranceProvider !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="{ required: true }"
            data-vv-validate-on="blur"
            data-vv-as="indemnity insurance provider"
            name="indemnityInsuranceProvider"
            label-placeholder="Indemnity Insurance Provider"
            placeholder="Indemnity Insurance Provider"
            v-model="userDetail.indemnityInsuranceProvider"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("indemnityInsuranceProvider")
          }}</span>
        </div>
      </div>

      <div
        v-if="
          (this.userDetail.userType === 'doctor' ||
          this.userDetail.userType === 'nurse') && 
          this.$isAuRegion()
        "
      >
        <div class="w-full">
          <vs-input
            :danger="errors.first('indemnityInsuranceNumber') ? true : false"
            :success="
              !errors.first('indemnityInsuranceNumber') &&
              userDetail.indemnityInsuranceNumber !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="{ required: true }"
            data-vv-validate-on="blur"
            data-vv-as="indemnity insurance number"
            name="indemnityInsuranceNumber"
            label-placeholder="Indemnity Insurance Number"
            placeholder="Indemnity Insurance Number"
            v-model="userDetail.indemnityInsuranceNumber"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("indemnityInsuranceNumber")
          }}</span>
        </div>
      </div>

      <div
        v-if="
          (this.userDetail.userType === 'doctor' ||
          this.userDetail.userType === 'nurse') && 
          this.$isAuRegion()
        "
      >
        <div class="w-full">
          <label>Indemnity Insurance Expiry Date</label
          >
          <datepicker
            style="margin-top: 0 !important"
            :danger="errors.first('indemnityInsuranceExpiry') ? true : false"
            :success="
              !errors.first('indemnityInsuranceExpiry') &&
              userDetail.indemnityInsuranceExpiry !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-model="userDetail.indemnityInsuranceExpiry"
            name="indemnityInsuranceExpiry"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="indemnity insurance expiry date"
            label-placeholder="Indemnity Insurance Expiry Date"
            placeholder="Indemnity Insurance Expiry Date"
            class="w-full mt-6"
            :format="customFormatter"
          ></datepicker>
          <span class="text-danger text-sm">{{
            errors.first("indemnityInsuranceExpiry")
          }}</span>
        </div>
      </div>

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'doctor'"
      >
        <div class="w-full">
          <vs-input
            :danger="errors.first('prescriberNumber') ? true : false"
            :success="
              !errors.first('prescriberNumber') &&
              userDetail.prescriberNumber !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="prescriber number"
            name="prescriberNumber"
            label-placeholder="Prescriber Number"
            placeholder="prescriberNumber"
            v-model="userDetail.prescriberNumber"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("prescriberNumber")
          }}</span>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
          this.userDetail.userType === 'clinic'
        "
      >
        <div class="w-full">
          <vs-input
            :danger="errors.first('companyName') ? true : false"
            :success="
              !errors.first('companyName') && userDetail.companyName !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="company name"
            name="companyName"
            label-placeholder="Company Name"
            placeholder="Company Name"
            v-model="userDetail.companyName"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("companyName")
          }}</span>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full">
          <vs-input
            :danger="errors.first('clinicName') ? true : false"
            :success="
              !errors.first('clinicName') && userDetail.clinicName !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="{ required: true }"
            data-vv-validate-on="blur"
            data-vv-as="clinic name"
            name="clinicName"
            label-placeholder="Clinic Name"
            placeholder="Clinic Name"
            v-model="userDetail.clinicName"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("clinicName")
          }}</span>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'franchise'"
      >
        <div class="w-full">
          <vs-input
            :danger="errors.first('franchiseBusinessName') ? true : false"
            :success="
              !errors.first('franchiseBusinessName') &&
              userDetail.franchiseBusinessName !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            data-vv-as="franchise business name"
            name="franchiseBusinessName"
            label-placeholder="Franchise Business Name (optional)"
            placeholder="Franchise Business Name (optional)"
            v-model="userDetail.franchiseBusinessName"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("franchiseBusinessName")
          }}</span>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
          this.userDetail.userType === 'clinic' ||
          this.userDetail.userType === 'doctor'
        "
      >
        <div class="w-full">
          <vs-input
            :danger="errors.first('abn') ? true : false"
            :success="!errors.first('abn') && userDetail.abn !== ''"
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="abn"
            name="abn"
            label-placeholder="ABN"
            placeholder="ABN"
            v-model="userDetail.abn"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{ errors.first("abn") }}</span>
        </div>
      </vs-col> -->

      <!-- <vs-col
        style="padding: 17px"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
          this.userDetail.userType === 'clinic'
        "
      >
        <div
          class="
            vs-component vs-con-input-label vs-input
            w-full
            mt-6
            vs-input-primary
            is-label-placeholder
          "
          :class="{
            'input-icon-validate-danger':
              errors.first('displayAddress') ||
              (addressObj.displayAddress && !addressObj.coordinates.latitude) ||
              checkDisplayAddress != addressObj.displayAddress,
            'input-icon-validate-success':
              addressObj.coordinates.latitude &&
              checkDisplayAddress == addressObj.displayAddress,
          }"
        >
          <div class="vs-con-input">
            <input
              type="text"
              placeholder
              ref="googleAutocomplete"
              v-validate="'required'"
              data-vv-validate-on="blur"
              :data-vv-as="
                userDetail.userType === 'clinic'
                  ? 'clinic street address'
                  : 'head office street address'
              "
              name="displayAddress"
              v-model="addressObj.displayAddress"
              class="vs-inputx vs-input--input normal"
              :class="{ hasValue: addressObj.displayAddress }"
              style="border: 1px solid rgba(0, 0, 0, 0.2)"
            />
            <span
              v-if="userDetail.userType === 'clinic'"
              class="
                input-span-placeholder
                vs-input--placeholder
                normal normal
                vs-placeholder-label
              "
              >Clinic Street Address</span
            >
            <span
              v-else
              class="
                input-span-placeholder
                vs-input--placeholder
                normal normal
                vs-placeholder-label
              "
              >Head office street address</span
            >
            <span class="input-icon-validate vs-input--icon-validate">
              <i
                v-if="
                  addressObj.coordinates &&
                  addressObj.coordinates.latitude &&
                  checkDisplayAddress == addressObj.displayAddress
                "
                valiconpack="material-icons"
                class="vs-icon notranslate icon-scale material-icons null"
                >done</i
              >
              <i
                v-else-if="
                  errors.first('displayAddress') ||
                  (addressObj.displayAddress &&
                    !addressObj.coordinates.latitude) ||
                  checkDisplayAddress != addressObj.displayAddress
                "
                valiconpack="material-icons"
                class="vs-icon notranslate icon-scale material-icons null"
                >error</i
              >
            </span>
          </div>
          <span>
            <div class="con-text-validation vs-input--text-validation">
              <span
                class="
                  span-text-validation span-text-validation-success
                  vs-input--text-validation-span
                "
              ></span>
            </div>
          </span>
        </div>
        <span class="text-danger text-sm">
          {{ errors.first("displayAddress") }}
        </span>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
          this.userDetail.userType === 'clinic'
        "
      >
        <div class="w-full">
          <vs-input
            :danger="errors.first('postalAddress') ? true : false"
            :success="
              !errors.first('postalAddress') && userDetail.postalAddress !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            data-vv-as="postal address"
            name="postalAddress"
            label-placeholder="Postal Address (optional)"
            placeholder="Postal Address (optional)"
            v-model="userDetail.postalAddress"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("postalAddress")
          }}</span>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
          this.userDetail.userType === 'clinic'
        "
      >
        <div class="w-full">
          <vs-input
            :danger="errors.first('bestContactName') ? true : false"
            :success="
              !errors.first('bestContactName') &&
              userDetail.bestContactName !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="{ required: true }"
            data-vv-validate-on="blur"
            data-vv-as="best contact name"
            name="bestContactName"
            label-placeholder="Best Contact Name"
            placeholder="Best Contact Name"
            v-model="userDetail.bestContactName"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("bestContactName")
          }}</span>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
          this.userDetail.userType === 'clinic'
        "
      >
        <div class="w-full">
          <vs-input
            :danger="errors.first('bestContactEmail') ? true : false"
            :success="
              !errors.first('bestContactEmail') &&
              userDetail.bestContactEmail !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required|email'"
            data-vv-validate-on="blur"
            data-vv-as="best contact email"
            name="bestContactEmail"
            label-placeholder="Best Contact Email"
            placeholder="Best Contact Email"
            v-model="userDetail.bestContactEmail"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("bestContactEmail")
          }}</span>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
          this.userDetail.userType === 'clinic'
        "
      >
        <div class="w-full">
          <vs-input
            :danger="errors.first('bestContactNumber') ? true : false"
            :success="
              !errors.first('bestContactNumber') &&
              userDetail.bestContactNumber !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="best contact number"
            name="bestContactNumber"
            label-placeholder="Best Contact Number"
            placeholder="Best Contact Number"
            v-model="userDetail.bestContactNumber"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("bestContactNumber")
          }}</span>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full">
          <vs-input
            :success="
              !errors.first('billingEmail') && userDetail.billingEmail !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('billingEmail') ? true : false"
            name="billingEmail"
            :danger-text="errors.first('billingEmail')"
            data-vv-as="billing email"
            placeholder="Billing Email (Optional)"
            v-model="userDetail.billingEmail"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("billingEmail")
          }}</span>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'franchise'"
      >
        <div class="w-full">
          <vs-input
            :danger="errors.first('invoiceEmail') ? true : false"
            :success="
              !errors.first('invoiceEmail') && userDetail.invoiceEmail !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required|email'"
            data-vv-validate-on="blur"
            data-vv-as="invoice email"
            name="invoiceEmail"
            label-placeholder="Invoice Email"
            placeholder="Invoice Email"
            v-model="userDetail.invoiceEmail"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("invoiceEmail")
          }}</span>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'franchise'"
      >
        <div class="w-full">
          <vs-checkbox
            ref="centralise"
            v-model="userDetail.wantCentralisebilling"
            class="mt-6"
            >Do you require centralised billing ?</vs-checkbox
          >
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'doctor'"
      >
        <div class="w-full">
          <vs-select
            :danger="errors.first('address') ? true : false"
            :success="!errors.first('address') && userDetail.address !== ''"
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-as="address"
            name="address"
            label="Address"
            autocomplete
            v-model="userDetail.address"
            class="w-full"
            id="addressSuggestion"
            @input-change="autoCompleteFunc($event.target.value)"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in addressItems"
            />
          </vs-select>
          <span class="text-danger text-sm">{{ errors.first("address") }}</span>
        </div>
      </vs-col> -->

      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'nurse'"
      >
        <div class="w-full"> -->
      <!-- <vs-input
            :danger="errors.first('nurseCosmeticExperience') ? true : false"
            :success="
              !errors.first('nurseCosmeticExperience') &&
                userDetail.nurseCosmeticExperience !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            data-vv-validate-on="blur"
            data-vv-as="nurse cosmetic experience"
            name="nurseCosmeticExperience"
            label-placeholder="Nurse Cosmetic Experience"
            placeholder="Nurse Cosmetic Experience"
            v-model="userDetail.nurseCosmeticExperience"
            class="w-full mt-6"
          />-->
      <!-- <label
            v-if="userDetail.nurseCosmeticExperience !== ''"
            style="opacity: 0.5"
            >Brief outline of your cosmetic experience</label
          >
          <vs-textarea
            style="margin-top: 0"
            :danger="errors.first('nurseCosmeticExperience') ? true : false"
            :success="
              !errors.first('nurseCosmeticExperience') &&
              userDetail.nurseCosmeticExperience !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            data-vv-validate-on="blur"
            data-vv-as="nurse cosmetic experience"
            name="nurseCosmeticExperience"
            label-placeholder="Nurse Cosmetic Experience"
            placeholder="Nurse Cosmetic Experience"
            v-model="userDetail.nurseCosmeticExperience"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">
            {{ errors.first("nurseCosmeticExperience") }}
          </span>
        </div>
      </vs-col> -->

    </div>  
    <div class="flex items-center justify-end">  
      <vs-button
        class="w-full lg:w-auto mt-4"
        @click="saveUserDetails"
        >Save Details</vs-button>
    </div>  
    </div>    
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { mapActions } from "vuex";
import Button from "../vuesax/button/Button";
import axios from "@/axios";
import { Validator } from "vee-validate";
import { updatedFieldChecker } from "../../../utils/updatedFieldChecker";

const dict = {
  custom: {
    bestContactNumber: {
      required: "Please enter your best contact number",
      regex: "Invalid Australian number",
    },
  },
};
Validator.localize("en", dict);

export default {
  name: "ProfileEdit",
  props: ["userData"],
  components: {
    Button,
    Datepicker,
  },
  data() {
    return {
      defaultPP: require("@/assets/images/profile/default.png"),
      userDetail: {
        id: "",
        firstName: "",
        lastName: "",
        nurseCosmeticExperience: "",
        preferredName: "",
        dateOfBirth: "",
        contactNumber: "",
        ahpraRegistrationNumber: "",
        ahpraExiryDate: "",
        indemnityInsuranceProvider: "",
        indemnityInsuranceNumber: "",
        indemnityInsuranceExpiry: "",
        ahpraCertificate: "",
        insuranceCertificate: "",
        signature: "",
        userType: "",
        profileImage: "",
        prescriberNumber: "",
        address: "",
        companyName: "",
        clinicName: "",
        franchiseBusinessName: "",
        abn: "",
        postalAddress: "",
        bestContactName: "",
        bestContactEmail: "",
        billingEmail: "",
        invoiceEmail: "",
        wantCentralisebilling: false,
        userAddressObject: null,
      },
      file: "",
      tempProfileImage: "",
      tempAphraCertificate: "",
      tempInsuranceCertificate: "",
      tempSignatureImage: "",
      addressItems: [],
      addressObj: {
        displayAddress: "",
        suburb: "",
        state: "",
        postCode: "",
        coordinates: { latitude: null, longitude: null },
      },
      checkDisplayAddress: "",
      fetchedData: null,
      updater: null,
      oldData: null
    };
  },
  methods: {
    ...mapActions("general", [
      "updateProfile",
      "updateAdminProfile",
      "updateFranchiseOrClinicProfile",
      "deleteUploadedFile",
      "changeProfileImage",
    ]),

    customFormatter(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },

    async saveUserDetails() {
      let isValid = await this.$validator.validateAll();
      if (this.userDetail.userType === "doctor" && this.$isAuRegion()) {
        this.errors.remove("bestContactEmail");
        if (
          moment(new Date(this.userDetail.dateOfBirth)).format("YYYY-MM-DD") >
          moment().subtract(18, "years").format("YYYY-MM-DD")
        ) {
          isValid = false;
          this.errors.add({
            field: "dateOfBirth",
            msg: "Your age must be 18 years or above",
          });
        } else {
          this.errors.remove("dateOfBirth");
        }
      }
      else{
        this.errors.remove("bestContactEmail");
        if(this.userDetail.contactNumber&&this.userDetail.contactNumber!==''){
          this.errors.remove("contactNumber");
        }
        isValid = !this.errors.any();
      }

      if (isValid) {
        let info = this.userDetail;
        info.userId = this.id,
        info.updater = `${this.oldData.firstName} ${this.oldData.lastName}`;
        info.updatedFields = updatedFieldChecker(this.oldData, info);

        if (info.userType === "admin" || info.userType === "superAdmin") {
          this.$vs.loading();

          const updateData = {
            firstName: info.firstName,
            lastName: info.lastName,
            profileImage: info.profileImage,
          };
          this.updateAdminProfile(updateData)
            .then((res) => {
              this.$vs.notify({
                title: "Success",
                text: "Profile updated successfully !",
                color: "success",
              });
              this.$emit("cancelEdit");
              this.$vs.loading.close();
            })
            .catch((err) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Failed",
                text: "Failed to update profile. Try again!",
                color: "danger",
              });

              if (err.status === 422) {
                this.mapServerErrors(this, err);
              }
            });
        } else if (
          info.userType === "clinic" ||
          info.userType === "franchise"
        ) {
          if (this.checkDisplayAddress !== this.addressObj.displayAddress) {
            this.errors.add({
              field: "displayAddress",
              msg: "Invalid address. Must choose from suggestion dropdown",
            });
            this.$vs.loading.close();
          } else {
            this.$vs.loading();

            const updateData = {
              userType: info.userType,
              firstName: info.firstName,
              lastName: info.lastName,
              contactNumber: info.contactNumber,
              companyName: info.companyName,
              clinicName: info.clinicName,
              abn: info.abn,
              bestContactName: info.bestContactName,
              bestContactEmail: info.bestContactEmail,
              bestContactNumber: info.bestContactNumber,
              billingEmail: info.billingEmail,
              invoiceEmail: info.invoiceEmail,
              franchiseBusinessName: info.franchiseBusinessName,
              wantCentralisebilling: info.wantCentralisebilling,
              profileImage: info.profileImage,
              address: this.addressObj,
              postalAddress: info.postalAddress,
            };

            this.updateFranchiseOrClinicProfile(updateData)
              .then((res) => {
                this.$vs.notify({
                  title: "Success",
                  text: "Profile updated successfully !",
                  color: "success",
                });
                this.$emit("cancelEdit");
                this.$vs.loading.close();
              })
              .catch((err) => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Failed",
                  text: "Failed to update profile. Try again!",
                  color: "danger",
                });

                if (err.status === 422) {
                  this.mapServerErrors(this, err);
                }
              });
          }
        } else if (
          info.userType === "subFranchise" ||
          info.userType === "subClinic"
        ) {
          this.$vs.loading();

          const updateData = {
            firstName: info.firstName,
            lastName: info.lastName,
            profileImage: info.profileImage,
            contactNumber: info.contactNumber,
          };
          this.updateAdminProfile(updateData)
            .then((res) => {
              this.$vs.notify({
                title: "Success",
                text: "Profile updated successfully !",
                color: "success",
              });
              this.$emit("cancelEdit");
              this.$vs.loading.close();
            })
            .catch((err) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Failed",
                text: "Failed to update profile. Try again!",
                color: "danger",
              });

              if (err.status === 422) {
                this.mapServerErrors(this, err);
              }
            });
        } else {
          this.$vs.loading();

          this.updateProfile(info)
            .then((res) => {
              this.$vs.notify({
                title: "Success",
                text: "Profile updated successfully !",
                color: "success",
              });
              this.$emit("cancelEdit");
              this.$emit("showAlert");
              this.$vs.loading.close();
            })
            .catch((err) => {
              this.$vs.loading.close();

              let errorText = "Failed to update profile. Try again!";
              if (err && err.data && err.data.message)
                errorText = err.data.message;

              this.$vs.notify({
                title: "Failed",
                text: errorText,
                color: "danger",
              });

              if (
                err &&
                err.data &&
                err.data.title &&
                err.data.title === "Invalid ABN"
              ) {
                this.errors.add({
                  field: "abn",
                  msg: err.data.message,
                });
              }

              if (err.status === 422) {
                this.mapServerErrors(this, err);
              }

              if (err.status === 417) {
                this.mapServerErrors(this, err);
              }
            });
        }
      }
    },
    change(key = "") {
      this.$vs.loading({
        text: "Uploading File...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + key + " div div",
      });
    },

    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        if (
          value.context.key === "latitude" ||
          value.context.key === "longitude"
        ) {
          self.errors.add({
            field: "displayAddress",
            msg: "Invalid address. Must choose from suggestion dropdown",
          });
        } else {
          self.errors.add({
            field: value.context.key,
            msg: value.message.replace(/"/g, ""),
          });
        }
      });
    },
    handleError(key = "") {
      this.$vs.loading.close("#" + key + " div div > .con-vs-loading");
      this.$vs.notify({
        title: "Upload Failed",
        text: "Unable to upload file at the moment.",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "danger",
      });
    },
    autoCompleteFunc(text) {
      if (text.length > 0)
        axios
          .get("/api/v1/getSuburbs", { params: { searchText: text } })
          .then((response) => {
            if (response.data.data.docs.length > 0) {
              const arr = response.data.data.docs.map((singleItem) => {
                singleItem.text = singleItem.displayAddress;
                singleItem.value = singleItem.displayAddress;
                return singleItem;
              });
              this.addressItems = [];
              this.addressItems = arr;
            }
          });
    },
    changePasswordHandler() {
      let user = this.$store.state.AppActiveUser.userRole;
      if (user === "superAdmin") {
        this.$router.push("/super-admin/change-password");
      } else {
        this.$router.push("/" + user + "/change-password");
      }
    },
    assingUserDetailData(userData) {
      this.userDetail.id = userData.uid;
      this.userDetail.firstName = userData.firstName || "";
      this.userDetail.lastName = userData.lastName || "";
      this.userDetail.nurseCosmeticExperience =
        userData.nurseCosmeticExperience || "";
      this.userDetail.preferredName = userData.preferredName || "";
      this.userDetail.dateOfBirth = userData.dateOfBirth || "";
      this.userDetail.contactNumber = userData.contactNumber || "";
      this.userDetail.ahpraRegistrationNumber =
        userData.ahpraRegistrationNumber || "";
      this.userDetail.ahpraExiryDate = userData.ahpraExiryDate;
      this.userDetail.indemnityInsuranceProvider =
        userData.indemnityInsuranceProvider || "";
      this.userDetail.indemnityInsuranceNumber =
        userData.indemnityInsuranceNumber || "";
      this.userDetail.indemnityInsuranceExpiry =
        userData.indemnityInsuranceExpiry;
      this.userDetail.ahpraCertificate = userData.ahpraCertificate || "";
      this.userDetail.insuranceCertificate =
        userData.insuranceCertificate || "";
      this.userDetail.signature = userData.signature || "";
      this.userDetail.userType = userData.userType || "";
      this.userDetail.profileImage = userData.profileImage || "";
      this.userDetail.prescriberNumber = userData.prescriberNumber || "";
      this.userDetail.address = userData.address
        ? userData.address.displayAddress
        : "";
      this.userDetail.companyName = userData.companyName || "";
      this.userDetail.clinicName = userData.clinicName || "";
      this.userDetail.franchiseBusinessName =
        userData.franchiseBusinessName || "";
      this.userDetail.abn = userData.abn || "";
      this.userDetail.postalAddress = userData.postalAddress || "";
      this.userDetail.bestContactName = userData.bestContactName || "";
      this.userDetail.bestContactEmail = userData.bestContactEmail || "";
      this.userDetail.bestContactNumber = userData.bestContactNumber || "";
      this.userDetail.billingEmail = userData.billingEmail || "";
      this.userDetail.invoiceEmail = userData.invoiceEmail || "";
      this.userDetail.wantCentralisebilling =
        userData.wantCentralisebilling || false;
      this.userDetail.userAddressObject = userData.address;

      this.addressObj = {
        displayAddress: userData.address ? userData.address.displayAddress : "",
        suburb: userData.address ? userData.address.suburb : "",
        state: userData.address ? userData.address.state : "",
        postCode: userData.address ? userData.address.postCode : "",
        coordinates:
          userData.address &&
          userData.address.geometry &&
          userData.address.geometry.coordinates
            ? {
                latitude: userData.address.geometry.coordinates[1],
                longitude: userData.address.geometry.coordinates[0],
              }
            : { latitude: null, longitude: null },
      };
      this.checkDisplayAddress = userData.address
        ? userData.address.displayAddress
        : "";
    },
    uploadImage(event, key = "profileImage", loadingClass = "") {
      // this.$vs.loading({
      //   text: "Uploading...",
      //   color: "#3dc9b3",
      //   background: "white",
      //   container: "#" + key,
      // });
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `.${loadingClass}`,
        scale: 0.45,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };

      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "profileImageUpload") {
            self.userDetail.profileImage = res.data.Location;
          }
          if (key === "aphraUpload") {
            self.userDetail.ahpraCertificate = res.data.Location;
          }
          if (key === "insuranceUpload") {
            self.userDetail.insuranceCertificate = res.data.Location;
          }
          if (key === "signature") {
            self.userDetail.signature = res.data.Location;
          }
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
        })
        .catch((err) => {
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
          if (err && err.response.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
    initilizeAutoComplete(element) {
      this.autocomplete = new google.maps.places.Autocomplete(element, {
        types: ["geocode"],
        componentRestrictions: { country: "au" },
      });
      this.autocomplete.addListener("place_changed", () => {
        let place = this.autocomplete.getPlace();
        this.errors.remove("displayAddress");
        let ac = place.address_components;
        this.checkDisplayAddress = place.formatted_address;
        let lat = place.geometry.location.lat();
        let lon = place.geometry.location.lng();
        this.addressObj.displayAddress = place.formatted_address;
        this.addressObj.coordinates.longitude = lon;
        this.addressObj.coordinates.latitude = lat;
      });
    },
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  created() {
    this.oldData = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_PROCESS_TOKEN}User`)
    );
    
    this.addressItems = [];
    this.$vs.loading();
    this.$store
      .dispatch(
        "general/getUserDetailForProfile",
        this.$store.state.AppActiveUser._id
      )
      .then((response) => {
        this.fetchedData = response.data.data;
        this.assingUserDetailData(this.fetchedData);
        if (this.fetchedData.address) {
          this.addressItems.push({
            value: this.fetchedData.address.displayAddress,
            text: this.fetchedData.address.displayAddress,
          });
          return null;
        } else return axios.get("/api/v1/getSuburbs");
      })
      .then((response) => {
        if (response && response.data.data.docs.length > 0) {
          const arr = response.data.data.docs.map((singleItem) => {
            singleItem.value = singleItem.displayAddress;
            singleItem.text = singleItem.displayAddress;
            return singleItem;
          });
          this.addressItems = arr;
        }
        this.$vs.loading.close();

        let element = this.$refs.googleAutocomplete;
        this.initilizeAutoComplete(element);
      })
      .catch((err) => {
        this.$vs.loading.close();
      });
  },
};
</script>

<style lang="scss">
.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }
}
.fileContainer {
  overflow: hidden;
  position: relative;
  margin-top: 65px;
}

.fileContainer [type="file"] {
  cursor: pointer;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}
.image-upload {
  cursor: pointer;
}

.cardDiv {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border: 1px solid #3dc9b3;
  margin-top: 40px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  color: #3dc9b3;
}

.con-input-upload.disabled-upload {
  display: none;
}

.list-group {
  border: 1px solid #ddd;
  z-index: 99999;
  position: absolute;
  background: #fff;
}

.list-group li {
  padding: 5px 10px;
  cursor: pointer;
}

.vs-select-primary ul:last-child {
  display: none;
}

.con-input-upload {
  height: 100px;
  width: auto;
}

div.img-upload {
  height: 100px !important;
  width: auto !important;
}

div.img-upload img {
  height: 100% !important;
  width: 100% !important;
}

@import "@/assets/scss/vuexy/pages/profile.scss";
</style>
